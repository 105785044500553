import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/misc.css';
ReactDOM.render(<div>
    <Main />
</div>
,
document.getElementById("root"));
